// extracted by mini-css-extract-plugin
export var projectFixedMenu = "style-module--projectFixedMenu--55734";
export var projectFixedMenuBottomLeft = "style-module--projectFixedMenuBottomLeft--81dd8";
export var projectFixedMenuBottomLeftItem = "style-module--projectFixedMenuBottomLeftItem--b0c2f";
export var projectFixedMenuBottomRight = "style-module--projectFixedMenuBottomRight--9fb75";
export var projectFixedMenuBottomRightItem = "style-module--projectFixedMenuBottomRightItem--88c58";
export var projectFixedMenuNum = "style-module--projectFixedMenuNum--b2434";
export var projectFixedMenuTitle = "style-module--projectFixedMenuTitle--2e8f8";
export var projectFixedMenuTopLeft = "style-module--projectFixedMenuTopLeft--cd7b1";
export var projectFixedMenuTopRight = "style-module--projectFixedMenuTopRight--52573";
export var projectFixedMenuWrap = "style-module--projectFixedMenuWrap--8b71a";