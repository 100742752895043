import React from "react"
import * as styles from './style.module.sass'

const ProjectSingleConcept = (props) => {
  return(
    <>
      <p
        className={styles.ProjectSingleConcept}
        dangerouslySetInnerHTML={{ __html: props.concept }}
      >
      </p>
      { props.conceptEn && (
        <p
          className={styles.ProjectSingleConceptEn}
          dangerouslySetInnerHTML={{ __html: props.conceptEn }}
        >
        </p>
      )}
    </>
  )
}

export default ProjectSingleConcept
