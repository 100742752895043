import React, {useState, useRef, useEffect} from "react"
import { Link } from "gatsby"
import * as styles from './style.module.sass'

import ProjectFixedMenu from '../../organisms/ProjectFixedMenu'
import ProjectImageSlider from '../../organisms/ProjectImageSlider'
import ProjectSingleMouseCursor from '../../atoms/ProjectSingleMouseCursor'
import ProjectConceptModal from "../../organisms/ProjectConceptModal"
import ProjectProcessModal from "../../organisms/ProjectProcessModal"
import ProjectThumbnailModal from "../../organisms/ProjectThumbnailModal"

const ProjectSingleTemplate = (props) => {
  const [nowImageNum, changeImageNum] = useState(0);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [processIsOpen, setIsProcessOpen] = useState(false);
  const [thumbModalIsOpen, setThumbModalOpen] = useState(false);
  const layoutName = useRef('/project-single/');
  const isMobile = useRef(
    typeof window === 'undefined'? false :
    (/android|ipod|ipad|iphone|macintosh/.test(navigator.userAgent.toLowerCase()) && 'ontouchend' in document)?true : false
  );

  return (
    <div className={styles.projectSingle}>
      <ProjectImageSlider
        layoutName={layoutName}
        nowImageNum={nowImageNum}
        post={props.post}
        thumbModalIsOpen={thumbModalIsOpen}
      />
      <ProjectFixedMenu
        layoutName={layoutName}
        layoutType={'main'}
        post={props.post}
        nowImageNum={nowImageNum}
        maxNum={props.post.projectGallary.imageList.length + 1}
        changeImageNum={(_num) => changeImageNum(_num)}
        previous={props.previous}
        next={props.next}
        modalIsOpen={modalIsOpen}
        setIsOpen={(_val)=>{setIsOpen(_val)}}
        processIsOpen={processIsOpen}
        setIsProcessOpen={(_val)=>{setIsProcessOpen(_val)}}
        thumbModalIsOpen={thumbModalIsOpen}
        setThumbModalOpen={(_val)=>{setThumbModalOpen(_val)}}
      />
      { !isMobile.current && (
        <ProjectSingleMouseCursor
          nowImageNum={nowImageNum}
          maxNum={props.post.projectGallary.imageList.length + 1}
          changeImageNum={(_num) => changeImageNum(_num)}
          previous={props.previous}
          next={props.next}
          modalIsOpen={modalIsOpen}
          processIsOpen={processIsOpen}
          thumbModalIsOpen={thumbModalIsOpen}
        />
      )}
      <ProjectConceptModal
        layoutName={layoutName}
        post={props.post}
        modalIsOpen={modalIsOpen}
        setIsOpen={(_val)=>{setIsOpen(_val)}}
        processIsOpen={processIsOpen}
        setIsProcessOpen={(_val)=>{setIsProcessOpen(_val)}}
        thumbModalIsOpen={thumbModalIsOpen}
        setThumbModalOpen={(_val)=>{setThumbModalOpen(_val)}}
      />
      {props.post.projectProcess.processList && (
        <ProjectProcessModal
          layoutName={layoutName}
          post={props.post}
          modalIsOpen={modalIsOpen}
          setIsOpen={(_val)=>{setIsOpen(_val)}}
          processIsOpen={processIsOpen}
          setIsProcessOpen={(_val)=>{setIsProcessOpen(_val)}}
          thumbModalIsOpen={thumbModalIsOpen}
          setThumbModalOpen={(_val)=>{setThumbModalOpen(_val)}}
        />
      )}
      <ProjectThumbnailModal
        layoutName={layoutName}
        post={props.post}
        changeImageNum={(_num) => changeImageNum(_num)}
        modalIsOpen={modalIsOpen}
        setIsOpen={(_val)=>{setIsOpen(_val)}}
        processIsOpen={processIsOpen}
        setIsProcessOpen={(_val)=>{setIsProcessOpen(_val)}}
        thumbModalIsOpen={thumbModalIsOpen}
        setThumbModalOpen={(_val)=>{setThumbModalOpen(_val)}}
      />
    </div>
  )
}

export default ProjectSingleTemplate
