// extracted by mini-css-extract-plugin
export var projectImageSlider = "style-module--projectImageSlider--a7706";
export var projectImageSliderFirstImageWrap = "style-module--projectImageSliderFirstImageWrap--acde1";
export var projectImageSliderItem = "style-module--projectImageSliderItem--9ba93";
export var projectImageSliderItemIsShow = "style-module--projectImageSliderItemIsShow--5022d";
export var projectImageSliderItemNoTransition = "style-module--projectImageSliderItemNoTransition--48ed0";
export var projectImageSliderItemVideo = "style-module--projectImageSliderItemVideo--2cac4";
export var projectImageSliderItemVideoContainer = "style-module--projectImageSliderItemVideoContainer--4f147";
export var projectImageSliderItemVideoCoverImage = "style-module--projectImageSliderItemVideoCoverImage--05c7c";
export var projectImageSliderItemVideoCoverImageIcon = "style-module--projectImageSliderItemVideoCoverImageIcon--65bf8";
export var projectImageSliderItemVideoCoverImageOverlay = "style-module--projectImageSliderItemVideoCoverImageOverlay--80533";
export var projectImageSliderItemVideoLoaded = "style-module--projectImageSliderItemVideoLoaded--8c211";