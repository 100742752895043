import React from "react"
import { graphql } from "gatsby"

import Seo from "../components/organisms/seo"
import ProjectSingleTemplate from "../components/template/ProjectSingleTemplate"

const ProjectSinglePage = ({ data }) => {
  return (
    <>
        <Seo title={data.post.title} />
        <ProjectSingleTemplate
          post={data.post}
          previous={data.previous}
          next={data.next}
        />
    </>
  )
}
export default ProjectSinglePage

export const pageQuery = graphql`
query(
    $id: String,
    $nextPostId: String,
    $previousPostId: String
  ) {
    post: wpPost(id: {eq: $id}) {
      title
      date(formatString: "Y")
      categories {
        nodes {
          name
        }
      }
      tags {
        nodes {
          name
        }
      }
      projectGallary {
        firstMedia {
          media
          vimeoUrl
          youtubeId
          thumbnail {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  formats: [AUTO, WEBP]
                  breakpoints: [750, 1920]
                  quality: 100
                  width: 1920
                )
              }
            }
          }
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  formats: [AUTO, WEBP]
                  breakpoints: [560, 1280, 2000, 3840]
                  quality: 100
                  width: 3840
                )
              }
            }
          }
        }
        imageList {
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  formats: [AUTO, WEBP]
                  breakpoints: [840, 1280, 2000, 3840]
                  quality: 100
                  width: 3840
                )
              }
            }
          }
        }
      }
      projectConcept {
        jpProjectDescription
        enProjectDescription
        creditList {
          roll
          names {
            name
          }
        }
      }
      projectProcess {
        processList {
          arrangement
          multiImagesArray {
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    breakpoints: [1280, 1600]
                    quality: 100
                    width: 1600
                  )
                }
              }
            }
            vimeoId
            youtubeId
            test
          }
          singleDescription
          singleImage {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  formats: [AUTO, WEBP]
                  breakpoints: [1280, 1800, 3000]
                  quality: 100
                  width: 3000
                )
              }
            }
          }
        }
      }
    }
    previous: wpPost(id: {eq: $previousPostId}) {
      slug
    }
    next: wpPost(id: {eq: $nextPostId}) {
      slug
    }
  }
`
