import React, {useRef} from "react"
import { Link } from "gatsby"
import * as styles from './style.module.sass'

import HoverProvider from '../HoverProvider'

const ProjectSingleIndexLink = (props) => {
  const isWin = useRef(typeof window === 'undefined'? false : window.navigator.userAgent.toLowerCase().indexOf("windows nt") !== -1? true : false);

  return(
    <HoverProvider
      hoverClassName={styles.isHover}
    >
      <Link
        to="/projects/"
        className={
          isWin.current? styles.IndexLink + ' ' + styles.IndexLinkIsWin : 
          styles.IndexLink
        }
      >
        Index
      </Link>
    </HoverProvider>
  )
}

export default ProjectSingleIndexLink
