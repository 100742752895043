import React, { useState } from "react"
import Modal from "react-modal"
import * as styles from "./style.module.sass"

import ProjectFixedMenu from "../../organisms/ProjectFixedMenu"
import ProjectSingleConceptBody from "../../molecules/ProjectSingleConceptBody"

// react-modalの初期設定
// root nodeのSelectorを設定
Modal.setAppElement("#___gatsby")

const ProjectConceptModal = props => {
  const customStyles = {
    overlay: {
      zIndex: "999",
      position: `fixed`,
      top: "0",
      left: "0",
      width: "100%",
      height: "calc(var(--vh, 1vh) * 100)",
      backgroundColor: `rgb(255, 255, 255)`
    },
    content: {
      position: `absolute`,
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      border: "none",
      borderRadius: "0px",
      padding: "0",
    },
  }

  const handleCloseModal = () => {
    props.setIsOpen(false)
  }

  return (
    <>
      <Modal
        isOpen={props.modalIsOpen}
        onRequestClose={() => handleCloseModal()}
        shouldCloseOnEsc={true}
        style={customStyles}
      >
        <ProjectSingleConceptBody
          post={props.post}
        />
        <div className={styles.conceptModalBottomBG}></div>
        <ProjectFixedMenu
          layoutName={props.layoutName}
          layoutType={"concept"}
          post={props.post}
          modalIsOpen={props.modalIsOpen}
          setIsOpen={(_val)=>{props.setIsOpen(_val)}}
          processIsOpen={props.processIsOpen}
          setIsProcessOpen={(_val)=>{props.setIsProcessOpen(_val)}}
          thumbModalIsOpen={props.thumbModalIsOpen}
          setThumbModalOpen={(_val)=>{props.setThumbModalOpen(_val)}}
        />
      </Modal>
      <div style={{display: "none"}}>
        <ProjectSingleConceptBody
          post={props.post}
        />
      </div>
    </>
  )
}

export default ProjectConceptModal
