import React, {useState, useEffect, useRef} from "react"
import Modal from "react-modal"
import * as styles from "./style.module.sass"

import ProjectFixedMenu from "../../organisms/ProjectFixedMenu"
import ProjectSingleMouseCursor from '../../atoms/ProjectSingleMouseCursor'
import ProjectProcessImagSlider from "../ProjectProcessImageSlider"

// react-modalの初期設定
// root nodeのSelectorを設定
Modal.setAppElement("#___gatsby")

const ProjectProcessModal = props => {
  const [nowImageNum, changeImageNum] = useState(0);
  const [isShowProcessText, toggleProcessText] = useState(false);
  const isMobile = useRef(
    typeof window === 'undefined'? false :
    (/android|ipod|ipad|iphone|macintosh/.test(navigator.userAgent.toLowerCase()) && 'ontouchend' in document)?true : false
  );

  const countProcessPhotoNum = () => {
    const processList = props.post.projectProcess.processList;
    let num = 0;
    for(let i = 0; i < processList.length; i++) {
        num += processList[i].arrangement == "single"? 1 : 4;
        if(i==processList.length-1) return num;
    }
  }

  const customStyles = {
    overlay: {
      zIndex: "999",
      position: `fixed`,
      top: "0",
      left: "0",
      width: "100%",
      height: "calc(var(--vh, 1vh) * 100)",
      backgroundColor: `rgb(255, 255, 255)`
    },
    content: {
      position: `absolute`,
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      border: "none",
      borderRadius: "0px",
      padding: "0",
      cursor: "none"
    },
  }

  const handleCloseModal = () => {
    props.setIsProcessOpen(false)
  }

  useEffect(() => {
    if(!isMobile.current) return;
    if(props.processIsOpen) toggleProcessText(false);
  }, [props.processIsOpen])

  return (
    <Modal
      isOpen={props.processIsOpen}
      onRequestClose={() => handleCloseModal()}
      shouldCloseOnEsc={true}
      style={customStyles}
    >
      <ProjectProcessImagSlider
        layoutName={props.layoutName}
        post={props.post}
        nowImageNum={nowImageNum}
        processIsOpen={props.processIsOpen}
        isShowProcessText={isShowProcessText}
      />
      { !isMobile.current && (
        <ProjectSingleMouseCursor
          nowImageNum={nowImageNum}
          maxNum={countProcessPhotoNum()}
          changeImageNum={(_num) => changeImageNum(_num)}
          isProcess={true}
          modalIsOpen={props.modalIsOpen}
          processIsOpen={props.processIsOpen}
        />
      )}
      <ProjectFixedMenu
        layoutName={props.layoutName}
        layoutType={"process"}
        post={props.post}
        nowImageNum={nowImageNum}
        maxNum={countProcessPhotoNum()}
        changeImageNum={(_num) => changeImageNum(_num)}
        previous={props.previous}
        next={props.next}
        modalIsOpen={props.modalIsOpen}
        setIsOpen={(_val)=>{props.setIsOpen(_val)}}
        processIsOpen={props.processIsOpen}
        setIsProcessOpen={(_val)=>{props.setIsProcessOpen(_val)}}
        thumbModalIsOpen={props.thumbModalIsOpen}
        setThumbModalOpen={(_val)=>{props.setThumbModalOpen(_val)}}
        isShowProcessText={isShowProcessText}
        toggleProcessText={(_val)=>{toggleProcessText(_val)}}
      />
    </Modal>
  )
}

export default ProjectProcessModal
