import React from "react"
import * as styles from './style.module.sass'

import HoverProvider from '../HoverProvider'

const ProjectSingleSwitchLayoutButton = (props) => {
  return(
    <HoverProvider
      hoverClassName={styles.isHover}
    >
      <button
        className={
          props.thumbModalIsOpen? styles.SwitchLayoutButton : styles.SwitchLayoutButton + " " + styles.SwitchLayoutButtonCard
        }
        onClick={()=>{
          if(props.processIsOpen) props.setIsProcessOpen(false);
          if(props.modalIsOpen) props.setIsOpen(false);
          props.setThumbModalOpen(!props.thumbModalIsOpen)
        }}
      >
        {
          !props.thumbModalIsOpen && (
            <>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </>
          )
        }
      </button>
    </HoverProvider>
  )
}

export default ProjectSingleSwitchLayoutButton
