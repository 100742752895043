import React, {useState, useEffect, useRef} from "react"
import * as styles from './style.module.sass'
import CursorSVG from '../../../images/cursor.svg'
import { navigate } from 'gatsby';

const DeviceSwitchWidth = 768;

const ProjectSingleMouseCursor = (props) => {
  const isMobile = useRef(
    typeof window === 'undefined'? false :
    (/android|ipod|ipad|iphone|macintosh/.test(navigator.userAgent.toLowerCase()) && 'ontouchend' in document)?true : false
  );
  const [mousePosition, setMousePosition] = useState({ x: null, y: null });
  const cursorDom = useRef(null);
  const ticking = useRef(false);
  const deviceWidth = useRef(null);
  const isEnableCursor = useRef(true);

  const onHideTarget = (_event) => {
    return (_event.target.tagName == "BUTTON" || _event.target.tagName == "A" || _event.target.tagName == "IFRAME" || _event.target.tagName == "SPAN")? true : false
  }

  const hideMouse = () => {
    if(cursorDom.current == null || !isEnableCursor.current) return;
    if(!cursorDom.current.classList.contains(styles.projectSingleMouseCursorIsHide)) cursorDom.current.classList.add(styles.projectSingleMouseCursorIsHide);
  }

  const setDirection = (_clientX) => {
    if(cursorDom.current == null || !isEnableCursor.current) return;
    if(_clientX< window.innerWidth/2 && !cursorDom.current.classList.contains(styles.projectSingleMouseCursorIsLeft)) cursorDom.current.classList.add(styles.projectSingleMouseCursorIsLeft);
    else if(_clientX >= window.innerWidth/2 && cursorDom.current.classList.contains(styles.projectSingleMouseCursorIsLeft)) cursorDom.current.classList.remove(styles.projectSingleMouseCursorIsLeft);
  }

  const mouseMoveHandler = (event) => {
    const { clientX, clientY } = event;
    setMousePosition({ x: clientX, y: clientY });
    setDirection(clientX);
    if(cursorDom.current == null || !isEnableCursor.current) return;
    if(cursorDom.current.classList.contains(styles.projectSingleMouseCursorIsHide) && !onHideTarget(event)) cursorDom.current.classList.remove(styles.projectSingleMouseCursorIsHide);
    else if(!cursorDom.current.classList.contains(styles.projectSingleMouseCursorIsHide) && onHideTarget(event)) cursorDom.current.classList.add(styles.projectSingleMouseCursorIsHide);
  };

  const nextProjectImage = () => {
    if(props.nowImageNum==0) navigate(`/project/${props.previous.slug}`);
    else{props.changeImageNum(props.nowImageNum - 1)}
  }

  const pervProjectImage = () => {
    if(props.nowImageNum==props.maxNum-1) navigate(`/project/${props.next.slug}`);
    else{props.changeImageNum(props.nowImageNum + 1)}
  }

  const switchVisual = () => {
    if(cursorDom.current == null || props.processIsOpen || props.modalIsOpen || props.thumbModalIsOpen || !isEnableCursor.current) return;
    if(cursorDom.current.classList.contains(styles.projectSingleMouseCursorIsHide)) return;
    if(cursorDom.current.classList.contains(styles.projectSingleMouseCursorIsLeft)) nextProjectImage();
    else pervProjectImage();
  }

  const nextProcessImage = () => {
    if(props.nowImageNum==0) props.changeImageNum(props.maxNum-1);
    else{props.changeImageNum(props.nowImageNum - 1)}
  }

  const pervProcessImage = () => {
    if(props.nowImageNum==props.maxNum-1) props.changeImageNum(0);
    else{props.changeImageNum(props.nowImageNum + 1)}
  }

  const switchProcessVisual = () => {
    if(cursorDom.current == null || !props.processIsOpen || !isEnableCursor.current ) return;
    if(cursorDom.current.classList.contains(styles.projectSingleMouseCursorIsHide)) return;
    if(cursorDom.current.classList.contains(styles.projectSingleMouseCursorIsLeft)) nextProcessImage();
    else pervProcessImage();
  }

  const switchActiveState = () => {
    if(window.innerWidth == deviceWidth.current) return false;
    if(window.innerWidth > DeviceSwitchWidth && !isEnableCursor.current) isEnableCursor.current = true;
    else if(window.innerWidth <= DeviceSwitchWidth && isEnableCursor.current) isEnableCursor.current = false;
    deviceWidth.current = window.innerWidth
  }

  const resizeEventTicking = () => {
    if( !ticking.current ) {
      window.requestAnimationFrame( () => {
        switchActiveState();
        ticking.current = false;
      });
      ticking.current = true;
    }
  }

  useEffect(() => {
    if(typeof window === 'undefined' || isMobile.current) return;
    switchActiveState();
    document.addEventListener("mousemove", mouseMoveHandler, {passive: true});
    document.addEventListener("mouseleave", hideMouse);
    return () => {
      document.removeEventListener("mousemove", mouseMoveHandler);
      document.removeEventListener("mouseleave", hideMouse);
    };
  }, []);

  useEffect(() => {
    if(typeof window === 'undefined' || isMobile.current) return;
    window.addEventListener(`resize`, resizeEventTicking);
    return () => window.removeEventListener(`resize`, resizeEventTicking)
  }, [])

  useEffect(() => {
    if(typeof window === 'undefined' || isMobile.current) return;
    document.addEventListener("click", props.isProcess? switchProcessVisual : switchVisual);
    return () => {
      document.removeEventListener("click", props.isProcess? switchProcessVisual : switchVisual);
    };
  }, [props.nowImageNum, props.processIsOpen, props.modalIsOpen, props.thumbModalIsOpen]);


  return(
    !isMobile.current? (
      <img
        ref={cursorDom}
        src={CursorSVG}
        className={styles.projectSingleMouseCursor + ' ' + styles.projectSingleMouseCursorIsHide}
        style={{ left: `${mousePosition.x}px`, top: `${mousePosition.y}px` }}
      />
    ) : (
      <></>
    )
  )
}

export default ProjectSingleMouseCursor
