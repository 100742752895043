import React, {useRef} from "react"
import * as styles from './style.module.sass'

const ProjectSingleTitle = (props) => {
  const isWin = useRef(typeof window === 'undefined'? false : window.navigator.userAgent.toLowerCase().indexOf("windows nt") !== -1? true : false);

  if(props.isEn){
    return(
      <h3
        className={
          isWin.current? styles.ProjectTitle + ' ' + styles.ProjectTitleIsWin : styles.ProjectTitle
        }
      >
        {props.projectTitle}
      </h3>
    )
  }else{
    return(
      <h1
        className={
          isWin.current? styles.ProjectTitle + ' ' + styles.ProjectTitleIsWin : styles.ProjectTitle
        }
      >
        {props.projectTitle}
      </h1>
    )
  }
}

export default ProjectSingleTitle
