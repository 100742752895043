import React from "react"
import * as styles from './style.module.sass'

import HoverProvider from '../HoverProvider'

const ProjectSingleInformationButton = (props) => (
  <HoverProvider
    hoverClassName={styles.ProjectSingleInformationButtonIsHover}
  >
    <button
      onClick={() => props.toggleProcessText((_val) => !_val)}
      className={styles.ProjectSingleInformationButton}
    >
      {props.isShowProcessText? (
        <span className={styles.ProjectSingleInformationButtonCloseIcon}></span>
      ):(
        <span className={styles.ProjectSingleInformationButtonInfoIcon}></span>
      )}
    </button>
  </HoverProvider>
)

export default ProjectSingleInformationButton
