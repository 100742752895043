import React from "react"
import * as styles from './style.module.sass'
import PlayIcon from '../../../images/play-icon.inline.svg'

import HoverProvider from '../../atoms/HoverProvider'
import ReactPlayer from 'react-player';
import SingleImage from '../../atoms/SingleImage'

const ProjectSingleImageList = (props) => {
  const projectGallary = props.post.projectGallary;

  const clickEvent = (_id) => {
    props.changeImageNum(_id);
    setTimeout(() => {
      props.setThumbModalOpen(false);
    }, 10);
  }

  return(
    <div className={styles.ProjectSingleImageList}>
      <ol className={styles.ProjectSingleImageListBody}>
        <HoverProvider
          hoverClassName={styles.isHover}
        >
          <li
            className={styles.ProjectSingleImageListItem}
            onClick={() => {clickEvent(0)}}
          >
            { projectGallary.firstMedia.media != "image" && (
              <div className={styles.ProjectSingleImageListItemBody}>
                <SingleImage
                  layoutName={props.layoutName}
                  source={projectGallary.firstMedia.thumbnail.localFile}
                  absolute={true}
                  sizes={"50vw"}
                />
                <span className={styles.ProjectSingleImageListItemBodyOverlay}>
                  <PlayIcon />
                </span>
              </div>
            )}
            { projectGallary.firstMedia.media == "image" && (
              <SingleImage
                  layoutName={props.layoutName}
                  source={projectGallary.firstMedia.image.localFile}
                  absolute={true}
                  sizes={"50vw"}
              />
            )}
          </li>
        </HoverProvider>
        {projectGallary.imageList.map((image, id) => (
            <HoverProvider
              hoverClassName={styles.isHover}
            >
              <li
                  key={id}
                  className={styles.ProjectSingleImageListItem}
                  onClick={() => {clickEvent(id + 1)}}
              >
                  <SingleImage
                      layoutName={props.layoutName}
                      source={image.image.localFile}
                      absolute={true}
                      sizes={"50vw"}
                  />
              </li>
            </HoverProvider>
        ))}
      </ol>
    </div>
  )
};

export default ProjectSingleImageList
