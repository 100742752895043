import React from "react"
import * as styles from './style.module.sass'

const ProjectSingleTag = (props) => {
  return(
    <section className={styles.ProjectSingleTag}>
      <h3>
        {props.tagTitle + " :"}
      </h3>
      {props.tagContents.map((content, id) => (
          <p
            key={id}
          >
            {content.name}
          </p>
      ))}
    </section>
  )
}

export default ProjectSingleTag
