import React from "react"
import * as styles from './style.module.sass'

import ProjectSingleTag from "../../atoms/ProjectSingleTag";

const ProjectSingleTagList = (props) => {
  const tagsArray = props.post.categories.nodes.concat(props.post.tags.nodes);

  return(
    <>
      <ProjectSingleTag
        tagTitle={"Year"}
        tagContents={[{name : props.post.date}]}
      />
      { props.post.projectConcept.creditList &&
        props.post.projectConcept.creditList.map((credit, id) => (
          <ProjectSingleTag
            key={id}
            tagTitle={credit.roll}
            tagContents={credit.names}
          />
        ))
      }
      <ProjectSingleTag
        tagTitle={"Tag"}
        tagContents={tagsArray}
        isTag={true}
      />
    </>
  )
};

export default ProjectSingleTagList
