import React, { useRef, useState, useEffect } from "react"
import * as styles from './style.module.sass'

import ReactPlayer from 'react-player';

import SingleImage from '../../atoms/SingleImage'

const DeviceSwitchWidth = 768;

const ProjectProcessImageSlider = (props) => {
    const isMobile = useRef(
        typeof window === 'undefined'? false :
        (/android|ipod|ipad|iphone|macintosh/.test(navigator.userAgent.toLowerCase()) && 'ontouchend' in document)?true : false
    );
    const sliderDom = useRef(null);
    const ticking = useRef(false);
    const [isPCLayout, setPCLayout] = useState(true)
    const deviceWidth = useRef(null);
    const startListId = useRef(0);
    const lastImageNum = useRef(0);

    const getItemClassName = (_id, _id2=0, _single=true) => {
        if(_id == 0 && _id2 == 0) lastImageNum.current = 0;
        let baseClassName = styles.projectProcessImageSliderItem;
        if(
            _single && props.nowImageNum == _id ||
            !_single && isPCLayout && lastImageNum.current <= props.nowImageNum && lastImageNum.current + _id2 <= props.nowImageNum && (props.nowImageNum - lastImageNum.current) <= 3 ||
            !_single && !isPCLayout && lastImageNum.current <= props.nowImageNum && lastImageNum.current + _id2%2 <= props.nowImageNum && (props.nowImageNum - lastImageNum.current) <= 1
        ) baseClassName += ' ' + styles.projectProcessImageSliderItemIsShow;
        if(_single && _id2==0) lastImageNum.current = lastImageNum.current + 1;
        if(!_single && isPCLayout && _id2==3) lastImageNum.current = lastImageNum.current + 4;
        if(!_single && !isPCLayout && (_id2==1 || _id2==3)) lastImageNum.current = lastImageNum.current + 2;
        return baseClassName;
    }

    const addHover = () => {
        if(sliderDom.current == null) return;
        const target = sliderDom.current.getElementsByClassName(styles.projectProcessImageSliderItemText);
        for(let i = 0; i < target.length; i++) if(!target[i].classList.contains(styles.projectProcessImageSliderItemTextIsShow)) target[i].classList.add(styles.projectProcessImageSliderItemTextIsShow)
    }

    const removeHover = () => {
        if(sliderDom.current == null) return;
        const target = sliderDom.current.getElementsByClassName(styles.projectProcessImageSliderItemText);
        for(let i = 0; i < target.length; i++) if(target[i].classList.contains(styles.projectProcessImageSliderItemTextIsShow)) target[i].classList.remove(styles.projectProcessImageSliderItemTextIsShow)
    }

    const switchLayout = () => {
        if(window.innerWidth == deviceWidth.current) return false;
        if(window.innerWidth > DeviceSwitchWidth && !isPCLayout || window.innerWidth <= DeviceSwitchWidth && isPCLayout) setPCLayout((_val) => !_val);
        deviceWidth.current = window.innerWidth
    }

    const resizeEventTicking = () => {
        if( !ticking.current ) {
            window.requestAnimationFrame( () => {
                switchLayout();
                ticking.current = false;
            });
            ticking.current = true;
        }
    }

    useEffect(() => {
        if(typeof window === 'undefined') return;
        switchLayout();
        window.addEventListener(`resize`, resizeEventTicking);
        return () => window.removeEventListener(`resize`, resizeEventTicking)
    })

    useEffect(() => {
        if(!isMobile.current) return;
        if(props.isShowProcessText) addHover();
        else removeHover();
    }, [props.isShowProcessText, props.processIsOpen])

    return (
        <div
            ref={sliderDom}
            className={styles.projectProcessImageSlider}
        >
            {props.post.projectProcess.processList.map((process, id) => {
                { return process.arrangement=="single" ? (
                    <div
                        key={id}
                        className={getItemClassName(id)}
                        onMouseEnter={(e) => {if(!isMobile.current) addHover(e)}}
                        onMouseLeave={(e) => {if(!isMobile.current) removeHover(e)}}
                    >
                        <span className={styles.projectProcessImageSliderItemContainer}>
                            <SingleImage
                                layoutName={props.layoutName}
                                source={process.singleImage.localFile}
                                absolute={true}
                                sizes={"250vw"}
                                absoluteOnlyPC={true}
                            />
                            <span className={styles.projectProcessImageSliderItemText}>
                                {process.singleDescription}
                            </span>
                        </span>
                    </div>
                ) : (
                    <div
                        key={id}
                        className={styles.projectProcessImageSliderGridWrap}
                    >
                        {process.multiImagesArray.map((image, id2) => (
                            <div
                                key={id2}
                                className={getItemClassName(id, id2, false) + " " + styles.projectProcessImageSliderItemGrid }
                                onMouseEnter={() => {if(!isMobile.current) addHover()}}
                                onMouseLeave={() => {if(!isMobile.current) removeHover()}}
                            >
                                <span className={styles.projectProcessImageSliderItemContainer}>
                                    {
                                        (image.vimeoId || image.youtubeId)? (
                                            <ReactPlayer
                                                url=
                                                {
                                                    image.vimeoId? `https://vimeo.com/${image.vimeoId}` : `https://www.youtube.com/watch?v=${image.youtubeId}`
                                                }
                                                muted={true}
                                                playsinline={true}
                                                loop={true}
                                                playing = {true}
                                                className={styles.projectProcessImageSliderItemIframeContainer}
                                            />
                                        ) : (
                                            <SingleImage
                                                layoutName={props.layoutName}
                                                source={image.image.localFile}
                                                absolute={true}
                                                sizes={"250vw"}
                                            />
                                        )
                                    }

                                    <span className={styles.projectProcessImageSliderItemText}>
                                        {image.test}
                                    </span>
                                </span>
                            </div>
                        ))}
                    </div>
                )}
            })}
        </div>
    )
}

export default ProjectProcessImageSlider
