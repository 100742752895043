import React, { useRef, useEffect, useState } from "react"
import * as styles from './style.module.sass'

import ProjectSingleIndexLink from "../../atoms/ProjectSingleIndexLink"
import ProjectSingleTitle from "../../atoms/ProjectSingleTitle"
import ProjectSingleSwitchLayoutButton from "../../atoms/ProjectSingleSwitchLayoutButton"
import ProjectSinglePhotoNum from "../../atoms/ProjectSinglePhotoNum"
import ProjectSingleConceptButton from '../../atoms/ProjectSingleConceptButton'
import ProjectSingleProcessButton from "../../atoms/ProjectSingleProcessButton"
import ProjectSingleSwitchImageButton from "../../atoms/ProjectSingleSwitchImageButton"
import ProjectSingleInformationButton from "../../atoms/ProjectSingleInformationButton"

const DeviceSwitchWidth = 768;

const ProjectFixedMenu = (props) => {
    const isMobile = useRef(
        typeof window === 'undefined'? false :
        (/android|ipod|ipad|iphone|macintosh/.test(navigator.userAgent.toLowerCase()) && 'ontouchend' in document)?true : false
    );
    const ticking = useRef(false);
    const nowImgNum = useRef(props.nowImageNum);
    const [isShowTitle, changeTitleState] = useState(true);

    const countProcessPhotoNum = () => {
        const processList = props.post.projectProcess.processList;
        let num = 0;
        for(let i = 0; i < processList.length; i++) {
            num += processList[i].arrangement == "single"? 1 : 4;
            if(i==processList.length-1) return num;
        }
    }

    const switchActiveState = () => {
        if(nowImgNum.current==0) {
            changeTitleState(true);
            return;
        }
        if(window.innerWidth > DeviceSwitchWidth) changeTitleState(() => true);
        else if(window.innerWidth <= DeviceSwitchWidth) changeTitleState(() => false);
    }

    const resizeEventTicking = () => {
        if( !ticking.current ) {
            window.requestAnimationFrame( () => {
            switchActiveState();
            ticking.current = false;
            });
            ticking.current = true;
        }
    }

    useEffect(() => {
        if(typeof window === 'undefined') return;
        window.addEventListener(`resize`, resizeEventTicking);
        return () => window.removeEventListener(`resize`, resizeEventTicking)
    }, [])

    useEffect(() => {
        nowImgNum.current = props.nowImageNum;
        switchActiveState();
    }, [props.nowImageNum])

    const photoNum = useRef(props.layoutType=="main"? props.post.projectGallary.imageList.length + 1 : props.layoutType=="process"? countProcessPhotoNum() : null);

    return (
        <div className={styles.projectFixedMenu}>
            <nav className={styles.projectFixedMenuWrap}>
                {
                    // 左上
                    props.layoutType == 'main' && (
                        <div className={styles.projectFixedMenuTopLeft}>
                            <ProjectSingleIndexLink />
                        </div>
                    )
                }
                {
                    // 左上
                    props.layoutType == 'process' && isMobile.current && (
                        <div className={styles.projectFixedMenuTopLeft}>
                            <ProjectSingleInformationButton
                                isShowProcessText={props.isShowProcessText}
                                toggleProcessText={(_val)=>{props.toggleProcessText(_val)}}
                            />
                        </div>
                    )
                }

                {
                    // 右上
                    props.layoutType == 'process' && (
                        <div className={styles.projectFixedMenuTopRight}>
                            <ProjectSingleTitle
                                projectTitle={"Process"}
                                isEn={true}
                            />
                        </div>
                    )
                }

                {(props.layoutType == 'main' && isShowTitle) && (
                    // タイトル
                    <div className={styles.projectFixedMenuTitle}>
                        <ProjectSingleTitle
                            projectTitle={props.post.title}
                        />
                    </div>
                )}

                <div className={styles.projectFixedMenuBottomRight}>
                    <div className={styles.projectFixedMenuBottomRightItem}>
                        <ProjectSingleConceptButton
                            modalIsOpen={props.modalIsOpen}
                            setIsOpen={(_val)=>{props.setIsOpen(_val)}}
                            processIsOpen={props.processIsOpen}
                            setIsProcessOpen={(_val)=>{props.setIsProcessOpen(_val)}}
                            thumbModalIsOpen = {props.thumbModalIsOpen}
                            setThumbModalOpen = {(_val) => props.setThumbModalOpen(_val)}
                        />
                    </div>
                    {props.post.projectProcess.processList && (
                        <div className={styles.projectFixedMenuBottomRightItem}>
                            <ProjectSingleProcessButton
                                modalIsOpen={props.modalIsOpen}
                                setIsOpen={(_val)=>{props.setIsOpen(_val)}}
                                processIsOpen={props.processIsOpen}
                                setIsProcessOpen={(_val)=>{props.setIsProcessOpen(_val)}}
                                thumbModalIsOpen = {props.thumbModalIsOpen}
                                setThumbModalOpen = {(_val) => props.setThumbModalOpen(_val)}
                            />
                        </div>
                    )}
                </div>


                <div className={styles.projectFixedMenuBottomLeft}>
                    <div className={styles.projectFixedMenuBottomLeftItem}>
                        <ProjectSingleSwitchLayoutButton
                            modalIsOpen={props.modalIsOpen}
                            setIsOpen={(_val)=>{props.setIsOpen(_val)}}
                            processIsOpen={props.processIsOpen}
                            setIsProcessOpen={(_val)=>{props.setIsProcessOpen(_val)}}
                            thumbModalIsOpen = {props.thumbModalIsOpen}
                            setThumbModalOpen = {(_val) => props.setThumbModalOpen(_val)}
                        />
                    </div>
                    {(props.layoutType == 'main' || props.layoutType == 'process') && (
                        <div className={styles.projectFixedMenuBottomLeftItem}>
                            <ProjectSingleSwitchImageButton
                                nowImageNum={props.nowImageNum}
                                maxNum={props.maxNum}
                                modalIsOpen={props.modalIsOpen}
                                thumbModalIsOpen = {props.thumbModalIsOpen}
                                processIsOpen={props.processIsOpen}
                                changeImageNum={(_num) => props.changeImageNum(_num)}
                                previous={props.previous}
                                next={props.next}
                            />
                        </div>
                    )}
                </div>


                {(props.layoutType == 'main' || props.layoutType == 'process') && (
                    <div className={styles.projectFixedMenuNum}>
                        <ProjectSinglePhotoNum
                            nowNum = {props.nowImageNum + 1}
                            allNum = {photoNum.current}
                        />
                    </div>
                )}


            </nav>
        </div>
    )
}

export default ProjectFixedMenu
