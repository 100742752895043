import React, {useRef} from "react"
import * as styles from './style.module.sass'

import HoverProvider from '../HoverProvider'

const ProjectSingleConceptButton = (props) => {
  const isWin = useRef(typeof window === 'undefined'? false : window.navigator.userAgent.toLowerCase().indexOf("windows nt") !== -1? true : false);

  return(
    <HoverProvider
      hoverClassName={styles.ProjectSingleConceptButtonIsHover}
    >
      <button
        onClick={() => {
          if(props.processIsOpen) props.setIsProcessOpen(false);
          if(props.thumbModalIsOpen) props.setThumbModalOpen(false);
          props.setIsOpen(!props.modalIsOpen)
        }}
        className={
          isWin.current? styles.ProjectSingleConceptButton + ' ' + styles.ProjectSingleConceptButtonIsWin : styles.ProjectSingleConceptButton
        }
      >
        { props.modalIsOpen? "Close" : "Concept" }
      </button>
    </HoverProvider>
  )
}

export default ProjectSingleConceptButton
