import React from "react"
import * as styles from './style.module.sass'

import ProjectSingleTitleInModal from "../../atoms/ProjectSingleTitleInModal";
import ProjectSingleConcept from "../../atoms/ProjectSingleConcept";
import ProjectSingleTagList from "../ProjectSingleTagList";

const ProjectSingleConceptBody = (props) => {
  return(
    <article className={styles.ProjectSingleConceptBody}>
      <div className={styles.ProjectSingleConceptBodyTitle}>
        <ProjectSingleTitleInModal
          projectTitle={props.post.title}
        />
      </div>
      <div className={styles.ProjectSingleConceptBodyContainer}>
        <div className={styles.ProjectSingleConceptBodyIntro}>
          <ProjectSingleConcept
            concept={props.post.projectConcept.jpProjectDescription}
            conceptEn={props.post.projectConcept.enProjectDescription}
          />
        </div>
        <div className={styles.ProjectSingleConceptBodyTags}>
          <ProjectSingleTagList
            post={props.post}
          />
        </div>
      </div>
    </article>
  )
};

export default ProjectSingleConceptBody
