import React, {useRef} from "react"
import * as styles from './style.module.sass'

const ProjectSinglePhotoNum = (props) => {
  const isWin = useRef(typeof window === 'undefined'? false : (window.navigator.userAgent.toLowerCase().indexOf("windows nt") !== -1)? true : false);


  return(
    <div className={
      isWin.current? styles.PhotoNum + ' ' + styles.PhotoNumIsWin : styles.PhotoNum
    }>
      <span>{props.nowNum}</span>
      <span className={styles.PhotoNumSeparation}></span>
      <span>{props.allNum}</span>
    </div>
  )
}

export default ProjectSinglePhotoNum
