import React, { useState, useRef, useEffect } from "react"
import * as styles from './style.module.sass'
import PlayIconPC from '../../../images/play-icon_pc.inline.svg'
import PlayIconSP from '../../../images/play-icon_sp.inline.svg'

import ReactPlayer from 'react-player';
import SingleImage from '../../atoms/SingleImage'

const DeviceSwitchWidth = 768;

const ProjectImageSlider = (props) => {
    const projectGallary = props.post.projectGallary;
    const player = useRef(null);
    const playerWrap = useRef(null);
    const videoThumb = useRef(null);
    const [playing, changePlayingState] = useState(false);
    const ticking = useRef(false);
    const [isPCLayout, setPCLayout] = useState(true)
    const deviceWidth = useRef(null);
    const [isNoTransition, setNoTransition] = useState(false);

    const getItemClassName = (_id) => {
        let baseClassName = styles.projectImageSliderItem;
        if(props.nowImageNum == _id || _id != 0 && !isPCLayout && props.nowImageNum >= _id && _id + (props.nowImageNum+1)%2 >= props.nowImageNum) baseClassName += ' ' + styles.projectImageSliderItemIsShow;
        if(isNoTransition) baseClassName += ' ' + styles.projectImageSliderItemNoTransition;
        return baseClassName;
    }

    const getVideoClassName = (_id) => {
        let baseClass = styles.projectImageSliderItemVideo;
        if(player.current != null && playerWrap.current != null ) baseClass += ' ' + styles.projectImageSliderItemVideoLoaded;
        return baseClass;
    }

    const showVideo = () => {
        if(player.current == null || playerWrap.current == null ) return;
        playerWrap.current.classList.add(styles.projectImageSliderItemVideoLoaded)
    }

    const clickThumbnail = () => {
        videoThumb.current.style.display = "none";
        changePlayingState(true);
    }

    const switchLayout = () => {
        if(window.innerWidth == deviceWidth.current) return false;
        if(window.innerWidth > DeviceSwitchWidth && !isPCLayout || window.innerWidth <= DeviceSwitchWidth && isPCLayout) setPCLayout((_val) => !_val);
        deviceWidth.current = window.innerWidth
    }

    const resizeEventTicking = () => {
        if( !ticking.current ) {
            window.requestAnimationFrame( () => {
                switchLayout();
                ticking.current = false;
            });
            ticking.current = true;
        }
    }

    useEffect(() => {
        if(typeof window === 'undefined') return;
        switchLayout();
        window.addEventListener(`resize`, resizeEventTicking);
        return () => window.removeEventListener(`resize`, resizeEventTicking)
    })

    useEffect(() => {
        changePlayingState(false);
    // }, [props.modalIsOpen, props.processIsOpen, props.nowImageNum])
    }, [props.nowImageNum])

    useEffect(() => {
        if(props.thumbModalIsOpen) setNoTransition(true);
        else{setNoTransition(false)}
    }, [props.thumbModalIsOpen])

    return (
        <div className={styles.projectImageSlider}>
            { projectGallary.firstMedia.media != "image" && (
                <div
                    className={
                        getItemClassName(0) + ' ' + getVideoClassName(0)
                    }
                    ref={playerWrap}
                >
                    <div className={styles.projectImageSliderItemVideoContainer}>
                        <button
                            ref={videoThumb}
                            onClick={() => clickThumbnail()}
                            className={styles.projectImageSliderItemVideoCoverImage}
                        >
                            <span
                                className={styles.projectImageSliderItemVideoCoverImageIcon}
                            >
                                <PlayIconSP />
                                <PlayIconPC />
                            </span>
                            <span
                                className={styles.projectImageSliderItemVideoCoverImageOverlay}
                            ></span>
                            <SingleImage
                                layoutName={props.layoutName}
                                source={projectGallary.firstMedia.thumbnail.localFile}
                                absolute={true}
                                sizes={"200vw"}
                            />
                        </button>
                        <ReactPlayer
                            ref={player}
                            url=
                            {
                                projectGallary.firstMedia.media == "vimeo"? `https://vimeo.com/${projectGallary.firstMedia.vimeoUrl}` : `https://www.youtube.com/watch?v=${projectGallary.firstMedia.youtubeId}` 
                            }
                            controls={true}
                            width='100%'
                            height='100%'
                            onProgress={showVideo}
                            onStart={() => {if(!playing) changePlayingState(true)}}
                            onPlay={() => {if(!playing) changePlayingState(true)}}
                            playing={playing}
                            playsinline={true}
                            style={playing? {opacity: 1} : {opacity: 0}}
                        />
                    </div>
                </div>
            )}
            { projectGallary.firstMedia.media == "image" && (
                <div className={getItemClassName(0)}>
                    <div className={styles.projectImageSliderFirstImageWrap}>
                        <SingleImage
                            layoutName={props.layoutName}
                            source={projectGallary.firstMedia.image.localFile}
                            absolute={true}
                            absoluteOnlyPC={true}
                            sizes={"200vw"}
                        />
                    </div>
                </div>
            )}

            {projectGallary.imageList.map((image, id) => (
                <div
                    key={id}
                    className={getItemClassName(id+1)}
                >
                    <SingleImage
                        layoutName={props.layoutName}
                        source={image.image.localFile}
                        absolute={true}
                        sizes={"200vw"}
                    />
                </div>
            ))}
        </div>
    )
}

export default ProjectImageSlider
