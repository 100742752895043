import React, {useRef} from "react"
import * as styles from './style.module.sass'

import HoverProvider from '../HoverProvider'

const ProjectSingleProcessButton = (props) => {
  const isWin = useRef(typeof window === 'undefined'? false : window.navigator.userAgent.toLowerCase().indexOf("windows nt") !== -1? true : false);

  return(
    <HoverProvider
      hoverClassName={styles.ProjectSingleConceptButtonIsHover}
    >
      <button
        onClick={() => {
          if(props.modalIsOpen) props.setIsOpen(false);
          if(props.thumbModalIsOpen) props.setThumbModalOpen(false);
          props.setIsProcessOpen(!props.processIsOpen)
        }}
        className={
          isWin.current? styles.ProjectSingleConceptButton + ' ' + styles.ProjectSingleConceptButtonIsWin : styles.ProjectSingleConceptButton
        }
      >
        { props.processIsOpen? "Close" : "Process" }
      </button>
    </HoverProvider>
  )
}

export default ProjectSingleProcessButton
