import React, {useEffect, useRef}  from "react"
import * as styles from './style.module.sass'
import { navigate } from 'gatsby';
import Arrow from "../../../images/project-switch-arrow.inline.svg"

import HoverProvider from '../HoverProvider'

const ProjectSingleSwitchImageButton = (props) => {
  const isMobile = useRef(
    typeof window === 'undefined'? false :
    (/android|ipod|ipad|iphone|macintosh/.test(navigator.userAgent.toLowerCase()) && 'ontouchend' in document)?true : false
  ),
  isMouseDown = useRef(false),
  touchStartX  = useRef(0),
  touchMoveX = useRef(0);

  const switchVisualLeft = () => {
    if(props.nowImageNum==0) navigate(`/project/${props.previous.slug}`);
    else{props.changeImageNum(props.nowImageNum - 1)}
  }

  const switchVisualRight = () => {
    if(props.nowImageNum==props.maxNum-1) navigate(`/project/${props.next.slug}`);
    else{props.changeImageNum(props.nowImageNum + 1)}
  }

  const switchProcessLeft = () => {
    if(props.nowImageNum!=0) props.changeImageNum(props.nowImageNum - 1)
  }

  const switchProcessRight = () => {
    if(props.nowImageNum!=props.maxNum-1 ) props.changeImageNum(props.nowImageNum + 1);
  }

  const leftButtonEvent = () => {
    if(props.processIsOpen) switchProcessLeft();
    else switchVisualLeft();
  }

  const rightButtonEvent = () => {
    if(props.processIsOpen) switchProcessRight();
    else switchVisualRight();
  }

  const setClass = (_left=false) => {
    let baseClass = styles.switchImageButtonItem;
    if(_left) baseClass += " " + styles.switchImageButtonItemLeft;
    if(props.processIsOpen && (_left && props.nowImageNum==0 || !_left && props.nowImageNum==props.maxNum-1)) baseClass += " " + styles.switchImageButtonItemIsDeactive;
    return baseClass;
  }

  const touchStart = (_event) => {
    isMouseDown.current = true;
    touchStartX.current = (_event.touches != undefined) ? _event.touches[0].pageX : _event.clientX;
  }

  const touchProcess = (_event) => {
    if( ( _event.touches != undefined || _event.clientX != 0 ) && isMouseDown.current){
        const touchX = (_event.touches != undefined) ? _event.changedTouches[0].pageX : _event.clientX;
        touchMoveX.current = touchX - touchStartX.current;
    }
  }

  const touchEnd = (_isProcess) => {
    if(!isMouseDown.current) return;
    //- 普通にスワイプした時
    if( isMouseDown.current && (touchMoveX.current > 50 || touchMoveX.current < -1 * 50) && !props.modalIsOpen && !props.thumbModalIsOpen) {
        if(props.processIsOpen) {
          ( touchMoveX.current > 50 )? switchProcessLeft() : switchProcessRight();
        }
        else {
          ( touchMoveX.current > 50 )? switchVisualLeft() : switchVisualRight();
        }
    }
    isMouseDown.current = false;
    touchMoveX.current = 0;
    return;
  }


  useEffect(() => {
    if(typeof window === 'undefined' || !isMobile.current) return;
    document.addEventListener('touchstart', touchStart, {passive: true});
    document.addEventListener('touchmove', touchProcess, {passive: true});
    document.addEventListener('touchend', touchEnd, {passive: true});
    document.addEventListener('touchcancel', touchEnd, {passive: true});
    return () => {
      document.removeEventListener('touchstart', touchStart);
      document.removeEventListener('touchmove', touchProcess);
      document.removeEventListener('touchend', touchEnd);
      document.removeEventListener('touchcancel', touchEnd);
    };
  }, [props.nowImageNum, props.processIsOpen, props.modalIsOpen, props.thumbModalIsOpen])

  return(
    <div className={styles.switchImageButton}>
      <HoverProvider
          hoverClassName={styles.switchImageButtonItemIsHover}
        >
        <button
          className={setClass(true)}
          onClick={leftButtonEvent}
        >
          <Arrow />
        </button>
      </HoverProvider>
      <HoverProvider
          hoverClassName={styles.switchImageButtonItemIsHover}
        >
        <button
          className={setClass()}
          onClick={rightButtonEvent}
        >
          <Arrow />
        </button>
      </HoverProvider>
    </div>
  )
}

export default ProjectSingleSwitchImageButton
