import React from "react"
import * as styles from './style.module.sass'

const ProjectSingleTitleInModal = (props) => (
  <h2
    className={styles.ProjectTitleInModal}
  >
    {props.projectTitle}
  </h2>
)

export default ProjectSingleTitleInModal
