import React, {useState} from "react"
import Modal from "react-modal"
import * as styles from "./style.module.sass"

import ProjectFixedMenu from "../../organisms/ProjectFixedMenu"
import ProjectSingleImageList from "../../molecules/ProjectSingleImageList"

// react-modalの初期設定
// root nodeのSelectorを設定
Modal.setAppElement("#___gatsby")

const ProjectThumbnailModal = props => {

  const customStyles = {
    overlay: {
      zIndex: "999",
      position: `fixed`,
      top: "0",
      left: "0",
      width: "100%",
      height: "calc(var(--vh, 1vh) * 100)",
      backgroundColor: `rgb(255, 255, 255)`
    },
    content: {
      position: `absolute`,
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      border: "none",
      borderRadius: "0px",
      padding: "0",
    },
  }

  const handleCloseModal = () => {
    props.setThumbModalOpen(false)
  }

  return (
    <Modal
      isOpen={props.thumbModalIsOpen}
      onRequestClose={() => handleCloseModal()}
      shouldCloseOnEsc={true}
      style={customStyles}
    >
      <ProjectSingleImageList
        layoutName={props.layoutName}
        post={props.post}
        changeImageNum={(_num) => props.changeImageNum(_num)}
        setThumbModalOpen={(_val)=>{props.setThumbModalOpen(_val)}}
      />
      <div className={styles.thumbModalBottomBG}></div>
      <ProjectFixedMenu
        layoutName={props.layoutName}
        layoutType={"thumbnail"}
        post={props.post}
        modalIsOpen={props.modalIsOpen}
        setIsOpen={(_val)=>{props.setIsOpen(_val)}}
        processIsOpen={props.processIsOpen}
        setIsProcessOpen={(_val)=>{props.setIsProcessOpen(_val)}}
        thumbModalIsOpen={props.thumbModalIsOpen}
        setThumbModalOpen={(_val)=>{props.setThumbModalOpen(_val)}}
      />
    </Modal>
  )
}

export default ProjectThumbnailModal
